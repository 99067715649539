<template>
  <b-container class="create-account-wizard-asset create-account-wizard-asset-step-01">
    <b-row no-gutters>
      <b-col sm="12" md="5" class="detail-text text-left pt-3 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5" align-self="center">
        <h2 class="text-dark">
          {{ $t(translationPath + 'title') }}
        </h2>
        <p>{{ $t(translationPath + 'intro_p1') }}</p>
      </b-col>
      <b-col sm="12" md="7" class="tag_wrapper pt-0 pb-2 px-3 py-md-5 px-md-4 py-lg-5 px-lg-5">
        <b-form-group
          label-class="FormLabels pr-4 text-black"
          label-for="title"
          label-cols-lg="3"
          label-align-lg="right"
          required
          :invalid-feedback="invalidTitleFeedback"
          :label="$t(translationPath + 'title_label')"
        >
          <template slot="description">{{ $t(translationPath + 'title_help_text') }}</template>
          <b-form-input id="title" class="rounded-pill"
            :placeholder="$t(translationPath+'title_placeholder')"
            :state="$v.title.$dirty ? !$v.title.$error : null"
            v-model="title"
            @input="onTitleInput"
          ></b-form-input>
        </b-form-group>
        <b-form-group
          label-class="FormLabels pr-4 text-black"
          label-for="description"
          label-cols-lg="3"
          label-align-lg="right"
          required
          :invalid-feedback="invalidDescriptionFeedback"
          :label="$t(translationPath + 'description_label')"
        >
          <template slot="description">{{ $t(translationPath + 'description_help_text') }}</template>
          <b-form-textarea id="description"
            :state="$v.description.$dirty ? !$v.description.$error : null"
            v-model="description"
            @input="onDescriptionInput"
          />
        </b-form-group>
        <b-form-group
          label-class="FormLabels pr-4 text-black"
          label-for="is-active"
          label-cols-lg="3"
          label-align-lg="right"
          required
          :label="$t(translationPath + 'is_active_label')"
        >
          <template slot="description">{{ $t(translationPath + 'is_active_help_text') }}</template>
          <b-form-select
            id="is-active"
            v-model="isActive"
            :options="[{ text: $t('common.yes'), value: 1 }, { text: $t('common.no'), value: 0 }]"
            @input="onIsActiveInput"
          />
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { required, minLength, maxLength } from 'vuelidate/lib/validators'

const isTitleUnique = getter => function () {
  return this.isTitleUnique
}
export default {
  name: 'WizardTagStep01',
  data () {
    return {
      translationPath: 'transactions.tags.wizard.step1.',
      title: '',
      description: '',
      isActive: 1,
      isTitleUnique: true
    }
  },
  validations: {
    title: { required, minLength: minLength(2), maxLength: maxLength(64), isTitleUnique: isTitleUnique() },
    description: { maxLength: maxLength(255) }
  },
  computed: {
    invalidTitleFeedback () {
      if (this.$v.title.required === false) { return this.$t(this.translationPath + 'errors.title.required') }
      if (this.$v.title.minLength === false) { return this.$t(this.translationPath + 'errors.title.min_length') }
      if (this.$v.title.maxLength === false) { return this.$t(this.translationPath + 'errors.title.max_length') }
      if (this.$v.title.isTitleUnique === false) { return this.$t(this.translationPath + 'errors.title.not_unique') }
      return ''
    },
    invalidDescriptionFeedback () {
      if (this.$v.description.maxLength === false) { return this.$t(this.translationPath + 'errors.description.max_length') }
      return ''
    }
  },
  methods: {
    emit () {
      this.$emit('validation', !this.$v.$invalid)
      if (!this.$v.$invalid) {
        this.$emit('updated', { title: this.title, description: this.description, is_active: this.isActive })
      }
    },
    onTitleInput () {
      this.isTitleUnique = true
      this.$v.title.$touch()
      this.emit()
    },
    onDescriptionInput () {
      this.$v.description.$touch()
      this.emit()
    },
    onIsActiveInput () {
      this.emit()
    }
  }
}
</script>
