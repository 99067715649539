<template>
  <b-container class="tag-wizard bg-light page-wrapper">
    <b-row class="main-content-wrapper create-tag-wrapper" no-gutters>
      <b-col>
        <b-breadcrumb :items="breadcrumbItems" class="dark"></b-breadcrumb>
        <h1 class="text-left mb-0" v-if="!isUpdate">{{ $t(translationPath + ('create_tag')) }}</h1>
        <h1 class="text-left mb-0" v-else>{{ $t(translationPath + ('edit_tag'), { title: editTagTitle }) }}</h1>
        <div class="step-container-v2">
          <div class="stepper bg-light mx-auto text-regular">
            <form-wizard :title="''" :subtitle="''" :stepSize="'xs'" :color="'#ffffff'"  :errorColor="'#ffffff'" ref="step">
              <tab-content :title="$t(translationPath + 'step1.title')">
                <wizard-tag-step-01
                  ref="Step1"
                  @validation="(status) => nextDisabled = !status"
                  @updated="(data) => tagModel = data"
                />
              </tab-content>
              <template slot="footer" slot-scope="props">
                <div :class="{ hasSpace: props.activeTabIndex !== 0 }">
                  <div class=wizard-footer-left>
                    <wizard-button  v-if="props.activeTabIndex > 0" @click.native="props.prevTab()" :style="props.fillButtonStyle" tabindex="0">
                      {{ $t('common.previous') }}
                    </wizard-button>
                  </div>
                  <div class="line" v-if="props.activeTabIndex !== 0"></div>
                  <div class="wizard-footer-right">
                    <wizard-button v-if="!props.isLastStep" @click.native="props.nextTab()" class="wizard-footer-right" :style="props.fillButtonStyle" :disabled="props.activeTabIndex === 1" tabindex="0">
                      {{ $t('common.next') }}
                    </wizard-button>
                    <wizard-button v-else @click.native="onComplete" class="wizard-footer-right finish-button" :style="props.fillButtonStyle" :disabled="nextDisabled" tabindex="0">
                      {{ $t('common.save') }}
                    </wizard-button>
                  </div>
                </div>
              </template>
            </form-wizard>
            <success-modal v-if="!isUpdate"
              :title="$t(translationPath + 'success_create_modal.title')"
              :message="$t(translationPath + 'success_create_modal.message', { tag: this.tagModel ? this.tagModel.title : '' })"
              :button="$t(translationPath + 'success_create_modal.btn_text')"
              :redirect="'/transactions/tags'"
              :orLink="'/redirect?to=createTag'"
              :orLinkText="$t(translationPath + 'success_create_modal.or_create_tag')"
              @hide="$router.push('/redirect?to=createTag')"
            ></success-modal>
            <success-modal v-if="isUpdate"
              :title="$t(translationPath + 'success_update_modal.title')"
              :message="$t(translationPath + 'success_update_modal.message')"
              :button="$t(translationPath + 'success_update_modal.btn_text')"
              :redirect="'/transactions/tags'"
              :orLink="'/redirect?to=createTag'"
              :orLinkText="$t(translationPath + 'success_update_modal.or_create_tag')"
              @hide="$router.push('/redirect?to=createTag')"
            ></success-modal>
          </div>
        </div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import axios from 'axios'
import { FormWizard, TabContent, WizardButton } from 'vue-form-wizard'
import SuccessModal from '@/components/modals/SuccessModal'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import WizardTagStep01 from './WizardTagStep01'

export default {
  name: 'TagWiard',
  components: { FormWizard, TabContent, WizardButton, WizardTagStep01, SuccessModal },
  data () {
    return {
      translationPath: 'transactions.tags.wizard.',
      isUpdate: false,
      editTagTitle: '',
      nextDisabled: true,
      tagModel: null
    }
  },
  computed: {
    breadcrumbItems () {
      const aBreadcrumb = [
        { text: this.$t('common.header.header_menu.transactions.transactions'), to: '/transactions/import/all' },
        { text: this.$t('transactions.tags.heading'), to: '/transactions/tags' },
        { text: '', to: '', active: true }
      ]

      return aBreadcrumb
    }
  },
  methods: {
    onComplete () {
      const data = { title: this.tagModel.title, is_active: this.tagModel.is_active }
      if (this.tagModel.description) {
        data.description = this.tagModel.description
      }

      const sApiMethod = this.isUpdate ? 'PUT' : 'POST'
      const sApiEndpoint = process.env.VUE_APP_ROOT_API + '/tags' + (this.isUpdate ? `/${this.$route.params.tag_id}` : '')

      axios({
        method: sApiMethod,
        url: sApiEndpoint,
        data: data
      })
        .then(response => {
          this.$bvModal.show('successModal')
        })
        .catch(err => {
          if (err.response.status === 422) {
            this.$refs.Step1.isTitleUnique = false
          } else {
            console.error(err)
          }
        })
    }
  },
  created () {
    if (this.$route.params.tag_id) {
      this.isUpdate = true
      axios.get(`${process.env.VUE_APP_ROOT_API}/tags/${this.$route.params.tag_id}`)
        .then(response => {
          this.editTagTitle = response.data.data.title
          this.tagModel = {
            title: response.data.data.title,
            description: response.data.data.description,
            is_active: Number(response.data.data.is_active)
          }
          this.$refs.Step1.title = this.tagModel.title
          this.$refs.Step1.description = this.tagModel.description
          this.$refs.Step1.isActive = this.tagModel.is_active
          this.nextDisabled = false
        })
        .catch(err => {
          console.error(err)
        })
    }
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/form-wizard-2.scss';
@import '@/assets/scss/modal.scss';
.tag-wizard {
  .tag_wrapper {
    border-left: 1px solid #D7D7D7;
    @media screen and (max-width: 767.98px) {
      border-left: none;
    }
  }
}
</style>
